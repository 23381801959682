import React, { useState } from 'react';
import styles from './MaskedId.module.scss';

function copyId(id) {
    return () => {
        navigator.clipboard.writeText(id).then(() => {
            console.debug(`Copied ${id} to clipboard`);
        });
    };
}

const MaskedText = ({ id }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    // Toggle reveal for mobile
    const handleClick = () => {
        setIsRevealed(!isRevealed);
    };

    return (
        <div
            className={`
        cursor-pointer
        select-none
        transition-all
        duration-300
        ease-in-out
        inline-block
        overflow-hidden
        text-ellipsis
        text-blue-600
        hover:text-blue-700
      `}
            onClick={handleClick} // For mobile interaction
            onMouseEnter={() => setIsRevealed(true)}   // For desktop hover
            onMouseLeave={() => setIsRevealed(false)}  // For desktop hover
        >
            <img src="/images/copy.svg" alt="copy" className={styles.copyTopIcon} onClick={copyId(id)} />
            {id.slice(id.length - 9)}
        </div>
    );
};

export default MaskedText;
