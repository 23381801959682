import React from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { getSymbolAssets } from "@/utils/symbols";
import clsx from "clsx";
import moment from "moment";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { setActiveGroupName } from "@/redux/actions/tradeInfo";
import { setActiveSymbol } from "@/redux/actions/tradeInfo";
import { assetListScrollToCoordinate } from "@/redux/actions/ui";

const SymbolIcon = ({ symbol, category }) => {
    const [assetImage1, assetImage2] = getSymbolAssets(
        symbol, //symbol.id,
        category //symbol?.group?.name || ""
    );
    return (
        <div className={styles.symbolImagesCell}>
            <div
                className={clsx(styles.symbolMainInfoImages, {
                    [styles.symbolMainInfoImagesSingleImage]: !assetImage1 || !assetImage2,
                })}>
                {/* <object data={assetImage1}> */}
                <img src={assetImage1} alt="" />
                {/* </object> */}
                {assetImage2 && (
                    // <object data={assetImage2}>
                    <img src={assetImage2} alt="" />
                    // </object>
                )}
            </div>
        </div>
    );
};

function extractTextDetails(text) {
    const iconMatch = text.match(
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{2934}-\u{1f18e}]/gu
    );
    const icon = iconMatch ? iconMatch[0] : "";
    let textWithoutIcon = text;

    if (icon) {
        textWithoutIcon = text.substring(icon.length).trim();
    }

    const regex = /(dropped|raised)\s(.*?)(?=\s(to|in)\s)/;
    const match = textWithoutIcon.match(regex);

    if (match) {
        const description = match[0];

        const assetNameIndex = textWithoutIcon.indexOf(description);
        const assetName = textWithoutIcon.substring(0, assetNameIndex).trim();

        const remainingText = textWithoutIcon.substring(assetNameIndex + description.length).trim();

        return {
            icon: icon,
            assetName: assetName.replace("!", ""),
            description: description,
            text: remainingText,
            priceTrend: match.includes("dropped") ? "dropped" : "raised",
        };
    }
    return {
        icon: icon,
        assetName: "",
        description: "",
        text: textWithoutIcon,
    };
}

export const MessageItem = ({
    symbol,
    category,
    date,
    message,

    digits,
}) => {
    const formattedDate = moment(date).format("DD.MM.YYYY HH:mmA");
    const dispatch = useDispatch();
    const { symbolsInfoById } = useTradeInfo();

    const { assetName, description, icon, text, priceTrend } = extractTextDetails(message);

    const handleRowClick = () => {
        const point = Number((digits / 100).toFixed(digits));
        if (symbolsInfoById[symbol]) {
            dispatch(setActiveGroupName(category));
            dispatch(assetListScrollToCoordinate(symbolsInfoById[symbol].id));
            setTimeout(() => {
                dispatch(setActiveSymbol(symbol, point));
            }, 400);
        }
    };

    return (
        <div className={clsx(styles.messageItem_container)}>
            <div onClick={handleRowClick} className={styles.column}>
                <SymbolIcon symbol={symbol} category={category} />
            </div>

            <div onClick={handleRowClick} className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.currency}>{assetName}</div>
                    <div className={styles.date}>{formattedDate}</div>
                </div>
                <div className={clsx(styles.message)}>
                    <div>
                        {icon} {assetName}
                        <span className={styles[priceTrend]}>{description}</span> {text}
                    </div>
                </div>
            </div>
        </div>
    );
};
