import { Button } from "@mui/material";
import { TradeTabs } from "@/redux/interfaces/IUI";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import styles from "../TradingChartInfoSection.module.scss";
import { analyticsEnabled } from '@/utils/url';

export const NavigationTabs = ({
    isTradeOrderViewOpen,
    openTradeOrderView,
    handleTabChange,
    navTab,
}: {
    isTradeOrderViewOpen: boolean;
    openTradeOrderView: () => void;
    handleTabChange: (tab: TradeTabs) => void;
    navTab: TradeTabs;
}) => {
    const { t } = useTranslation("common");
    const navigationTabs = analyticsEnabled()
        ? Object.values(TradeTabs)
        : Object.values(TradeTabs).slice(0, 2);

    return (
        <div className={styles.navigationTabs}>
            {!isTradeOrderViewOpen && (
                <div className={styles.navTab}>
                    <Button
                        className={styles.navTabOpenTrade}
                        variant="contained"
                        color="primary"
                        onClick={() => openTradeOrderView()}
                        disableRipple>
                        <img src="/images/trade-btn-icon.svg" alt="Open trade" />
                        {t("open_trade")}
                    </Button>
                </div>
            )}
            {navigationTabs.map(tab => {
                return (
                    <div className={styles.navTab} onClick={() => handleTabChange(tab)} key={tab}>
                        <Button
                            className={clsx(styles.navTabButton, {
                                [styles.active]: tab === navTab,
                            })}
                            variant="contained"
                            disableRipple>
                            {t(tab)}
                        </Button>
                    </div>
                );
            })}
        </div>
    );
};
