import React from "react";

import { ISymbolInfo } from "@/services/trade/symbols";

import styles from "./SymbolInfoImages.module.scss";

interface IProps {
    imageName1: string;
    imageName2: string;
    symbol: ISymbolInfo;
    showSymbolMainInfoName?: boolean;
}

const SymbolInfoImages = ({
    imageName1,
    imageName2,
    symbol,
    showSymbolMainInfoName,
}: IProps): JSX.Element => {
    let assetImage1 = imageName1;
    let assetImage2 = imageName2;

    if (assetImage1 && !assetImage1.includes("http")) {
        assetImage1 = `/images/symbols/${assetImage1}`;
    }

    if (assetImage2 && !assetImage2.includes("http")) {
        assetImage2 = `/images/symbols/${assetImage2}`;
    }

    return (
        <div className={styles.container}>
            <div className={styles.symbolImages}>
                {/* <object data={assetImage1} type="image/png"> */}
                <img src={assetImage1} alt="eur icon" />
                {/* </object> */}
                {assetImage2 && (
                    // <object data={assetImage2} type="image/png">
                    <img src={assetImage2} alt="usd icon" />
                    // </object>
                )}
            </div>
            {showSymbolMainInfoName && (
                <span className={styles.symbolMainInfoName}>{symbol.ex.displayName || symbol.id}</span>
            )}
        </div>
    );
};

export default React.memo(SymbolInfoImages);
