import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { setChartTab, setTradeOrderView } from "@/redux/actions/ui";
import TradingChart from "./parts/TradingChart";
import TradingInfo from "./parts/TradingInfo";
// import useChartLicenseReminder from "@/hooks/common/useChartLicenseReminder";
import { TimeIndicator } from "./parts/TimeIndicator";
import { TradeTabs } from "@/redux/interfaces/IUI";
import { NavigationTabs } from "./parts/NavigationTabs";
import { AnalystForecasts } from "./parts/AnalystForecasts/ui";
import { TechnicalAnalysis } from "./parts/TechnicalAnalysis/ui";
import styles from "./TradingChartInfoSection.module.scss";

const TradingChartInfoSection: React.FC = () => {
    // for development
    // useChartLicenseReminder();

    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { isTradeOrderViewOpen, isAssetsTableExpanded, navTab, isOpenTradesOpen } = useUI();
    const [forceChartReload, setForceChartReload] = useState(false);
    const isNarrow = isTradeOrderViewOpen && isAssetsTableExpanded;
    const introStep = useIntroStep();

    const handleTabChange = (newTab: TradeTabs) => {
        dispatch(setChartTab(newTab));
    };

    const isOverlayed = introStep === 4;
    const isCurrentIntroStep = introStep === 3;

    const openTradeOrderView = () => dispatch(setTradeOrderView(true));

    useEffect(() => {
        if (isCurrentIntroStep) {
            openTradeOrderView();
        }
        if (introStep === 4 && navTab === TradeTabs.info) {
            handleTabChange(TradeTabs.chart);
        }
    }, [isCurrentIntroStep]);

    useEffect(() => {
        if (ref.current && navTab === TradeTabs.chart) {
            // possible bug with hide-tlb-item class removing in chart source
            const element = document.querySelector("prochart .layout1 ul.toolbar-adv>li");
            if (element && element.classList.contains("hide-tlb-item")) {
                setForceChartReload(true);
                setTimeout(() => {
                    setForceChartReload(false);
                }, 100);
            }
        }
    }, [navTab]);

    return (
        <div
            className={clsx(styles.section, {
                [styles.narrow]: isNarrow,
                [styles.overlayed]: isOverlayed,
            })}
            id="trading_chart_section"
            ref={ref}>
            <div
                className={clsx(styles.sectionHeader, {
                    [styles.overlayed]: isOverlayed,
                })}>
                <NavigationTabs
                    isTradeOrderViewOpen={isTradeOrderViewOpen}
                    handleTabChange={handleTabChange}
                    navTab={navTab}
                    openTradeOrderView={openTradeOrderView}
                />
                <div className={styles.dateTime}>
                    <TimeIndicator />
                </div>
            </div>

            <div
                className={clsx(styles.content, {
                    [styles.tradesTableOpen]: isOpenTradesOpen,
                })}>
                <div
                    className={clsx(styles.tabContent, {
                        [styles.hidden]: navTab !== TradeTabs.chart,
                    })}>
                    {!forceChartReload && <TradingChart wrapRef={ref} />}
                </div>
                {navTab === TradeTabs.info && (
                    <div
                        className={clsx(styles.tabContent, {
                            [styles.scrollContent]: true,
                        })}>
                        <TradingInfo />
                    </div>
                )}
                {navTab === TradeTabs.analystForecasts && (
                    <div className={styles.tabContent}>
                        <AnalystForecasts />
                    </div>
                )}
                {navTab === TradeTabs.technicalAnalysis && (
                    <div className={styles.tabContent}>
                        <TechnicalAnalysis />
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(TradingChartInfoSection);
